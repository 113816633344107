<template>
  <div>
    <transition appear name="fade">
      <div class="modal-wrapper">
        <div class="modal">
          <div class="content">
            <button
              v-on:click="closeModal()"
              class="close"
              aria-label="Close the ebook download window"
            >
              <span aria-hidden="true">×</span>
            </button>
            <img src="/assets/images/logo-footer.svg" alt="AWS and Deloitte" />
            <h1 v-html="title" />
            <p class="subtitle" v-html="subtitle" />
            <p>
              Thank you for downloading the {{ type }}. <br /><br />
              If your file doesn't automatically start downloading, use this
              link to manually
              <a :href="file" id="ebook-download" download>download the PDF</a>.
              <br /><br />
              For more information, please visit:<br />
              <a href="https://migrate-modernize-transform.com/"
                >migrate-modernize-transform.com</a
              >
            </p>
          </div>
        </div>

        <div v-on:click="closeModal()" class="overlay"></div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "eBookModal",
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    file: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      previousFocus: null,
      lastFocusable: null,
      firstFocusable: null,
      showSolutionVideoModal: false,
      selectedLink: null,
      downloadTimeout: null,
    };
  },
  mounted() {
    window.addEventListener("keydown", this.onKeydown);
    this.getFocusable();
    this.previousFocus = document.activeElement;
    if (this.firstFocusable) {
      this.firstFocusable.focus();
    }
    document.body.style.paddingRight = `${
      window.innerWidth - document.body.offsetWidth
    }px`;
    document.body.classList.add("modal-open");
    this.downloadTimeout = window.setTimeout(function () {
      document.getElementById("ebook-download").click();
    }, 2000);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.onKeydown);
    this.previousFocus.focus();
    document.body.classList.remove("modal-open");
    document.body.style.paddingRight = 0;
    window.clearTimeout(this.downloadTimeout);
  },
  methods: {
    linkEvent: function (action, category, label) {
      this.$gtag.event(action, {
        event_category: category,
        event_label: label,
      });
    },
    linkClick: function (action, category, event) {
      this.linkEvent(action, category, event.target.getAttribute("href"));
    },
    onKeydown: function (evt) {
      if (this.status) {
        if (evt.which === 27) {
          evt.preventDefault();
          this.closeModal();
        }
      }
    },
    getFocusable: function () {
      const focusable = Array.prototype.slice.call(
        this.$el.querySelectorAll(
          'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'
        )
      );
      this.firstFocusable = focusable[0];
      this.lastFocusable = focusable[focusable.length - 1];
    },
    closeModal: function () {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
body.modal-open {
  position: fixed;
  height: 100vh;
  width: 100%;
  overflow-y: hidden;
}
</style>
<style scoped lang="scss">
.modal-wrapper {
  z-index: 10;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 2;
    &.active {
      display: block;
    }
  }
  .modal {
    background: #ffffff;
    width: 100%;
    max-width: 800px;
    max-height: 100%;
    position: absolute;
    overflow: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-family: Verdana, Helvetica, Arial, sans-serif;
    font-weight: 300;
    line-height: 24px;
    z-index: 3;
    padding: 75px 2.5%;

    .content {
      .close {
        position: absolute;
        top: 18px;
        right: 18px;
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        padding: 10px;
        height: 37px;
        span {
          font-size: 38px;
          line-height: 15px;
          height: 17px;
          display: inline-block;
          transition: 300ms ease;
        }
        &:hover {
          color: #ff0000;
        }
      }
      img {
        display: block;
        max-width: 100%;
        margin: 0 auto 42px;
      }
      h1 {
        font-weight: normal;
        font-style: italic;
        font-size: ((40/18) * 1rem);
        line-height: (56/40);
        text-align: center;
        color: #01aeeb;
        margin-bottom: 17.4px;
      }
      .subtitle {
        margin: 0 auto 42px;
        font-weight: 600;
        font-size: ((24/18) * 1rem);
        line-height: (26/24);
        text-align: center;
        color: #585858;
        opacity: 1;
      }
      p {
        font-weight: normal;
        font-size: ((20/18) * 1rem);
        line-height: (26/20);
        text-align: center;
        color: #000;
        max-width: 660px;
        margin: 17.4px auto;
        a {
          color: #01aeeb;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>
